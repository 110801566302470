// extracted by mini-css-extract-plugin
export var cartCounter = "TabletNavigation__cartCounter__NjZcZ";
export var column = "TabletNavigation__column__gevYs";
export var firstLevelLink = "TabletNavigation__firstLevelLink__hFRA6";
export var hasMenu = "TabletNavigation__hasMenu__VRFRq";
export var icon = "TabletNavigation__icon__RIrcK";
export var innerWrapper = "TabletNavigation__innerWrapper__Qj3Y9";
export var link = "TabletNavigation__link__OBP30";
export var logo = "TabletNavigation__logo__SfaXw";
export var mainLinks = "TabletNavigation__mainLinks__ttUgl";
export var mainNavigation = "TabletNavigation__mainNavigation__WQ8zm";
export var nested = "TabletNavigation__nested__bLdtU";
export var outerWrapper = "TabletNavigation__outerWrapper__xMZWZ";
export var resetList = "TabletNavigation__resetList__mWvBL";
export var row = "TabletNavigation__row__DKkld";
export var submenu = "TabletNavigation__submenu__G9Xdo";
export var submenuLink = "TabletNavigation__submenuLink__jb9_m";
export var submenuNested = "TabletNavigation__submenuNested__flGP8";
export var submenuNestedLast = "TabletNavigation__submenuNestedLast__PMNNt";
export var topLinks = "TabletNavigation__topLinks__d6sCh";
export var topNavigation = "TabletNavigation__topNavigation__jFkCf";