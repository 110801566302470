// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Cart from "../../Cart/Cart.res.js";
import * as User from "../../../../models/User.res.js";
import * as React from "react";
import * as BlogLink from "../BlogLink/BlogLink.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as NavDropdown from "../NavDropdown/NavDropdown.res.js";
import * as Routes_Page from "../../../../routes/common/Routes_Page.res.js";
import * as NavSignedOut from "../NavSignOut/NavSignedOut.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as NavigationHooks from "../NavigationHooks.res.js";
import * as Routes_BlogPost from "../../../../routes/common/Routes_BlogPost.res.js";
import * as Routes_Location from "../../../../routes/common/Routes_Location.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as Routes_Resource from "../../../../routes/common/Routes_Resource.res.js";
import * as Routes_Services from "../../../../routes/common/Routes_Services.res.js";
import * as ResourceCategory from "../../../../models/ResourceCategory.res.js";
import * as TabletSignedInNav from "./TabletSignedInNav.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../../../routes/common/Routes_Marketplace.res.js";
import * as Global from "Client/common/global";
import LogoWhiteSvg from "Images/logo-white.svg";
import * as TabletNavigationScss from "./TabletNavigation.scss";
import * as Routes_LocationBrochure from "../../../../routes/common/Routes_LocationBrochure.res.js";
import * as Routes_ProviderBrochure from "../../../../routes/common/Routes_ProviderBrochure.res.js";

var css = TabletNavigationScss;

function TabletNavigation$TabletNavigation(props) {
  var isTransparent = props.isTransparent;
  var isHomepage = props.isHomepage;
  var totalCartQuantity = props.totalCartQuantity;
  var cartQty = props.cartQty;
  var setCartQty = props.setCartQty;
  var toggleCart = props.toggleCart;
  var userLoginStatus = props.userLoginStatus;
  var shouldHaveFixedHeader = isHomepage || !props.isLandingPage || isTransparent;
  var scrollAlphaChannel = NavigationHooks.useScrollTransition(isHomepage || isTransparent);
  React.useEffect((function () {
          setCartQty(cartQty);
        }), []);
  var alpha = (
      (isHomepage || isTransparent) === true ? scrollAlphaChannel : 1.0
    ).toString();
  var tmp;
  tmp = userLoginStatus === "LoggedIn" ? JsxRuntime.jsx(TabletSignedInNav.make, {
          userData: props.userData,
          setUserData: props.setUserData,
          toggleCart: toggleCart,
          totalCartQuantity: totalCartQuantity,
          isConcierge: props.isConcierge,
          isNotOnlyProviderMember: props.isNotOnlyProviderMember
        }) : JsxRuntime.jsx(NavSignedOut.make, {
          device: "Tablet",
          toggleCart: toggleCart,
          totalCartQuantity: totalCartQuantity
        });
  var tmp$1;
  var exit = 0;
  if (isTransparent || shouldHaveFixedHeader) {
    exit = 1;
  } else {
    tmp$1 = "transparent";
  }
  if (exit === 1) {
    tmp$1 = "linear-gradient(135deg, rgba(5, 15, 57, " + alpha + ") 0%, rgba(25, 63, 119, " + alpha + ") 100%)";
  }
  return JsxRuntime.jsxs("header", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_Page.contact,
                                      className: css.link,
                                      children: JsxRuntime.jsx("strong", {
                                            children: "Get colocation pricing now! " + Global.DCContactNumber
                                          })
                                    }),
                                tmp
                              ],
                              className: css.topNavigation
                            }),
                        JsxRuntime.jsxs("nav", {
                              children: [
                                JsxRuntime.jsx("a", {
                                      children: JsxRuntime.jsx("img", {
                                            className: css.logo,
                                            alt: "Logo",
                                            src: LogoWhiteSvg
                                          }),
                                      "aria-label": "Datacenters.com",
                                      href: "/"
                                    }),
                                JsxRuntime.jsxs("ul", {
                                      children: [
                                        JsxRuntime.jsx(NavDropdown.make, {
                                              title: "Colocation",
                                              level: "Primary",
                                              device: "Tablet",
                                              linksGroup: "MainLinks",
                                              children: JsxRuntime.jsxs("ul", {
                                                    children: [
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Project.Colocation.$$new,
                                                                  className: css.submenuLink,
                                                                  children: "Colocation RFP"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Location.index,
                                                                  className: css.submenuLink,
                                                                  children: "Data Center Locations"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_LocationBrochure.index,
                                                                  className: css.submenuLink,
                                                                  children: "Data Center Brochures"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Marketplace.colocation,
                                                                  className: css.submenuLink,
                                                                  children: "Colocation Marketplace"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Services.Colocation.index,
                                                                  className: css.submenuLink,
                                                                  children: "Colocation Overview"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Provider.colocation,
                                                                  className: css.submenuLink,
                                                                  children: "Colocation Providers"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Provider.index,
                                                                  className: css.submenuLink,
                                                                  children: "All Providers"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_ProviderBrochure.index,
                                                                  className: css.submenuLink,
                                                                  children: "Provider Brochures"
                                                                })
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.submenu,
                                                          css.nested
                                                        ])
                                                  })
                                            }),
                                        JsxRuntime.jsx(NavDropdown.make, {
                                              title: "Bare Metal",
                                              level: "Primary",
                                              device: "Tablet",
                                              linksGroup: "MainLinks",
                                              children: JsxRuntime.jsxs("ul", {
                                                    children: [
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Project.BareMetal.$$new,
                                                                  className: css.submenuLink,
                                                                  children: "Bare Metal RFP"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx(NavDropdown.make, {
                                                            title: "Bare Metal IaaS",
                                                            level: "Secondary",
                                                            device: "Tablet",
                                                            linksGroup: "MainLinks",
                                                            children: JsxRuntime.jsxs("ul", {
                                                                  children: [
                                                                    JsxRuntime.jsx("li", {
                                                                          children: JsxRuntime.jsx(A.make, {
                                                                                href: Routes_Services.BareMetal.index,
                                                                                className: css.submenuLink,
                                                                                children: "Bare Metal Services"
                                                                              })
                                                                        }),
                                                                    JsxRuntime.jsx("li", {
                                                                          children: JsxRuntime.jsx(A.make, {
                                                                                href: Routes_Services.BareMetal.enzu,
                                                                                className: css.submenuLink,
                                                                                children: "Enzu Bare Metal"
                                                                              })
                                                                        }),
                                                                    JsxRuntime.jsx("li", {
                                                                          children: JsxRuntime.jsx(A.make, {
                                                                                href: Routes_Services.BareMetal.evocative,
                                                                                className: css.submenuLink,
                                                                                children: "Evocative Bare Metal"
                                                                              })
                                                                        }),
                                                                    JsxRuntime.jsx("li", {
                                                                          children: JsxRuntime.jsx(A.make, {
                                                                                href: Routes_Services.BareMetal.zenlayer,
                                                                                className: css.submenuLink,
                                                                                children: "Zenlayer Bare Metal"
                                                                              })
                                                                        }),
                                                                    JsxRuntime.jsx("li", {
                                                                          children: JsxRuntime.jsx(A.make, {
                                                                                href: Routes_Services.BareMetal.hivelocity,
                                                                                className: css.submenuLink,
                                                                                children: "Hivelocity Bare Metal"
                                                                              })
                                                                        })
                                                                  ],
                                                                  className: css.submenuNestedLast
                                                                })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Provider.bareMetal,
                                                                  className: css.submenuLink,
                                                                  children: "Bare Metal Providers"
                                                                })
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.submenu,
                                                          css.nested
                                                        ])
                                                  })
                                            }),
                                        JsxRuntime.jsx(NavDropdown.make, {
                                              title: "Cloud",
                                              level: "Primary",
                                              device: "Tablet",
                                              linksGroup: "MainLinks",
                                              children: JsxRuntime.jsxs("ul", {
                                                    children: [
                                                      JsxRuntime.jsx(NavDropdown.make, {
                                                            title: "Cloud RFP",
                                                            level: "Secondary",
                                                            device: "Tablet",
                                                            linksGroup: "MainLinks",
                                                            children: JsxRuntime.jsxs("ul", {
                                                                  children: [
                                                                    JsxRuntime.jsx("li", {
                                                                          children: JsxRuntime.jsx(A.make, {
                                                                                href: Routes_Project.IaasServer.$$new,
                                                                                className: css.submenuLink,
                                                                                children: "Servers"
                                                                              })
                                                                        }),
                                                                    JsxRuntime.jsx("li", {
                                                                          children: JsxRuntime.jsx(A.make, {
                                                                                href: Routes_Project.IaasStorage.$$new,
                                                                                className: css.submenuLink,
                                                                                children: "Storage"
                                                                              })
                                                                        })
                                                                  ],
                                                                  className: css.submenuNestedLast
                                                                })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Services.Multicloud.index,
                                                                  className: css.submenuLink,
                                                                  children: "Deploy Multicloud"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Provider.cloud,
                                                                  className: css.submenuLink,
                                                                  children: "Cloud Providers"
                                                                })
                                                          })
                                                    ],
                                                    className: css.submenu
                                                  })
                                            }),
                                        JsxRuntime.jsx(NavDropdown.make, {
                                              title: "Resource Downloads",
                                              level: "Primary",
                                              device: "Tablet",
                                              linksGroup: "MainLinks",
                                              children: JsxRuntime.jsx("ul", {
                                                    children: Belt_Array.map(props.resources, (function (resource) {
                                                            var slug = resource.slug;
                                                            return JsxRuntime.jsx("li", {
                                                                        children: slug === "all-resources" ? JsxRuntime.jsx(A.make, {
                                                                                href: Routes_Resource.resourcesIndex,
                                                                                className: css.submenuLink,
                                                                                children: resource.navigationName
                                                                              }) : JsxRuntime.jsx(A.make, {
                                                                                href: "/resources/" + resource.slug,
                                                                                className: css.submenuLink,
                                                                                children: resource.navigationName
                                                                              })
                                                                      }, resource.slug);
                                                          })),
                                                    className: css.submenu
                                                  })
                                            }),
                                        JsxRuntime.jsx(NavDropdown.make, {
                                              title: "Blog",
                                              level: "Primary",
                                              device: "Tablet",
                                              linksGroup: "MainLinks",
                                              children: JsxRuntime.jsxs("ul", {
                                                    children: [
                                                      JsxRuntime.jsx("li", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_BlogPost.news,
                                                                  className: css.submenuLink,
                                                                  children: "All News"
                                                                })
                                                          }, "all-news"),
                                                      Belt_Array.map(props.blogLinks, (function (news) {
                                                              return JsxRuntime.jsx("li", {
                                                                          children: JsxRuntime.jsx(A.make, {
                                                                                href: Routes_BlogPost.newsCategories(news.slug),
                                                                                className: css.submenuLink,
                                                                                children: news.navigationName
                                                                              })
                                                                        }, news.slug);
                                                            }))
                                                    ],
                                                    className: css.submenu
                                                  })
                                            })
                                      ],
                                      className: css.mainLinks
                                    })
                              ],
                              className: css.mainNavigation
                            })
                      ],
                      className: css.innerWrapper
                    }),
                JsxRuntime.jsx(Cart.make, {
                      isOpen: props.isCartOpen,
                      closeModal: toggleCart,
                      setCartQty: setCartQty,
                      userLoginStatus: userLoginStatus
                    })
              ],
              className: css.outerWrapper,
              style: {
                background: tmp$1,
                position: shouldHaveFixedHeader ? "fixed" : "absolute"
              }
            });
}

var TabletNavigation = {
  css: css,
  make: TabletNavigation$TabletNavigation
};

function TabletNavigation$default(props) {
  return JsxRuntime.jsx(TabletNavigation$TabletNavigation, {
              userLoginStatus: User.LoginStatus.fromBool(props.isUserSignedIn),
              userData: User.fromJs(props.userData),
              setUserData: props.setUserData,
              resources: Belt_Array.map(props.resources, ResourceCategory.fromJs),
              isCartOpen: props.isCartOpen,
              isConcierge: props.isConcierge,
              isNotOnlyProviderMember: props.isNotOnlyProviderMember,
              toggleCart: props.toggleCart,
              setCartQty: props.setCartQty,
              cartQty: props.cartQty,
              totalCartQuantity: props.totalCartQuantity,
              isLandingPage: props.isLandingPage,
              isHomepage: props.isHomepage,
              isTransparent: props.isTransparent,
              blogLinks: Belt_Array.map(props.blogLinks, BlogLink.fromJs)
            });
}

var $$default = TabletNavigation$default;

export {
  TabletNavigation ,
  $$default as default,
}
/* css Not a pure module */
