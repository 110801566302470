// Generated by ReScript, PLEASE EDIT WITH CARE


function fromJs(js) {
  return {
          navigationName: js.navigationName,
          slug: js.slug
        };
}

export {
  fromJs ,
}
/* No side effect */
