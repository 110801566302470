// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as NavSignedInScss from "./NavSignedIn.scss";

var css = NavSignedInScss;

function NavSignedIn(props) {
  var setUserData = props.setUserData;
  var tmp;
  switch (props.device) {
    case "Desktop" :
    case "Tablet" :
        tmp = css.link;
        break;
    case "Mobile" :
        tmp = css.submenuLink;
        break;
    
  }
  return JsxRuntime.jsx("a", {
              children: JsxRuntime.jsx("strong", {
                    children: "Logout"
                  }),
              className: Cx.cx([tmp]),
              id: "logout",
              href: "#!",
              onClick: (function (param) {
                  $$Promise.wait(Api.signOut(), (function (x) {
                          if (x.TAG !== "Ok") {
                            return SentryLogger.error1({
                                        rootModule: "NavSignedIn",
                                        subModulePath: /* [] */0,
                                        value: "make",
                                        fullPath: "NavSignedIn.make"
                                      }, "SignOut::Error", [
                                        "Error",
                                        x._0
                                      ]);
                          }
                          setUserData(/* [] */0);
                          Url.visit("/");
                        }));
                })
            });
}

var make = NavSignedIn;

export {
  css ,
  make ,
}
/* css Not a pure module */
