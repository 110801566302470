// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";
import * as NavDropdownScss from "./NavDropdown.scss";

var css = NavDropdownScss;

var initialState = {
  show: false
};

function reducer(state, action) {
  return {
          show: !state.show
        };
}

function NavDropdown(props) {
  var device = props.device;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var tmp;
  tmp = device === "Tablet" ? css.tabletLink : css.mobileLink;
  var tmp$1;
  tmp$1 = props.linksGroup === "TopLinks" ? css.adminLink : "";
  var tmp$2;
  tmp$2 = device === "Tablet" ? css.tabletList : css.mobileList;
  var tmp$3;
  tmp$3 = props.level === "Primary" ? css.primary : css.secondary;
  return JsxRuntime.jsxs("li", {
              children: [
                JsxRuntime.jsx("span", {
                      children: props.title,
                      className: Cx.cx([
                            css.submenu,
                            state.show ? css.menuOpened : css.menuClosed,
                            tmp,
                            tmp$1
                          ]),
                      onClick: (function (_event) {
                          dispatch("Toggle");
                        })
                    }),
                state.show ? JsxRuntime.jsx("div", {
                        children: props.children
                      }) : null
              ],
              className: Cx.cx([
                    tmp$2,
                    tmp$3
                  ])
            });
}

var make = NavDropdown;

export {
  css ,
  initialState ,
  reducer ,
  make ,
}
/* css Not a pure module */
