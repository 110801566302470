// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Cart from "../../Cart/Cart.res.js";
import * as User from "../../../../models/User.res.js";
import * as React from "react";
import * as Control from "../../Control/Control.res.js";
import * as BlogLink from "../BlogLink/BlogLink.res.js";
import * as IconCart from "../../../icons/IconCart.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as NavDropdown from "../NavDropdown/NavDropdown.res.js";
import * as NavSignedOut from "../NavSignOut/NavSignedOut.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as NavigationHooks from "../NavigationHooks.res.js";
import * as Routes_BlogPost from "../../../../routes/common/Routes_BlogPost.res.js";
import * as Routes_Location from "../../../../routes/common/Routes_Location.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as Routes_Resource from "../../../../routes/common/Routes_Resource.res.js";
import * as Routes_Services from "../../../../routes/common/Routes_Services.res.js";
import * as ResourceCategory from "../../../../models/ResourceCategory.res.js";
import * as MobileSignedInNav from "./MobileSignedInNav.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../../../routes/common/Routes_Marketplace.res.js";
import LogoWhiteSvg from "Images/logo-white.svg";
import * as MobileNavigationScss from "./MobileNavigation.scss";
import * as Routes_LocationBrochure from "../../../../routes/common/Routes_LocationBrochure.res.js";
import * as Routes_ProviderBrochure from "../../../../routes/common/Routes_ProviderBrochure.res.js";

var css = MobileNavigationScss;

var initialState = {
  show: false
};

function reducer(state, action) {
  return {
          show: !state.show
        };
}

function MobileNavigation$MobileNavigation(props) {
  var totalCartQuantity = props.totalCartQuantity;
  var cartQty = props.cartQty;
  var setCartQty = props.setCartQty;
  var toggleCart = props.toggleCart;
  var userLoginStatus = props.userLoginStatus;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var shouldEnableScrollTransition = props.isHomepage || props.isLandingPage || props.isTransparent;
  var scrollAlphaChannel = NavigationHooks.useScrollTransition(shouldEnableScrollTransition);
  console.log(scrollAlphaChannel);
  React.useEffect((function () {
          setCartQty(cartQty);
        }), []);
  var alpha = scrollAlphaChannel.toString();
  var tmp;
  if (match[0].show) {
    var tmp$1;
    tmp$1 = userLoginStatus === "LoggedIn" ? JsxRuntime.jsx(MobileSignedInNav.make, {
            userData: props.userData,
            setUserData: props.setUserData,
            isConcierge: props.isConcierge,
            isNotOnlyProviderMember: props.isNotOnlyProviderMember
          }) : JsxRuntime.jsx(NavSignedOut.make, {
            device: "Mobile",
            toggleCart: toggleCart,
            totalCartQuantity: totalCartQuantity
          });
    tmp = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx("nav", {
                children: JsxRuntime.jsxs("ul", {
                      children: [
                        JsxRuntime.jsx(NavDropdown.make, {
                              title: "Colocation",
                              level: "Primary",
                              device: "Mobile",
                              linksGroup: "MainLinks",
                              children: JsxRuntime.jsxs("ul", {
                                    children: [
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_Project.Colocation.$$new,
                                                  className: css.submenuLink,
                                                  children: "Colocation RFP"
                                                })
                                          }),
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_Location.index,
                                                  className: css.submenuLink,
                                                  children: "Data Center Locations"
                                                })
                                          }),
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_LocationBrochure.index,
                                                  className: css.submenuLink,
                                                  children: "Data Center Brochures"
                                                })
                                          }),
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_Marketplace.colocation,
                                                  className: css.submenuLink,
                                                  children: "Colocation Marketplace"
                                                })
                                          }),
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_Services.Colocation.index,
                                                  className: css.submenuLink,
                                                  children: "Colocation Overview"
                                                })
                                          }),
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_Provider.colocation,
                                                  className: css.submenuLink,
                                                  children: "Colocation Providers"
                                                })
                                          }),
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_Provider.index,
                                                  className: css.submenuLink,
                                                  children: "All Providers"
                                                })
                                          }),
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_ProviderBrochure.index,
                                                  className: css.submenuLink,
                                                  children: "Provider Brochures"
                                                })
                                          })
                                    ],
                                    className: css.resetList
                                  })
                            }),
                        JsxRuntime.jsx(NavDropdown.make, {
                              title: "Bare Metal",
                              level: "Primary",
                              device: "Mobile",
                              linksGroup: "MainLinks",
                              children: JsxRuntime.jsxs("ul", {
                                    children: [
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_Project.BareMetal.$$new,
                                                  className: css.submenuLink,
                                                  children: "Bare Metal RFP"
                                                })
                                          }),
                                      JsxRuntime.jsx(NavDropdown.make, {
                                            title: "Bare Metal IaaS",
                                            level: "Secondary",
                                            device: "Mobile",
                                            linksGroup: "MainLinks",
                                            children: JsxRuntime.jsxs("ul", {
                                                  children: [
                                                    JsxRuntime.jsx("li", {
                                                          children: JsxRuntime.jsx(A.make, {
                                                                href: Routes_Services.BareMetal.index,
                                                                className: css.submenuLink,
                                                                children: "Bare Metal Services"
                                                              })
                                                        }),
                                                    JsxRuntime.jsx("li", {
                                                          children: JsxRuntime.jsx(A.make, {
                                                                href: Routes_Services.BareMetal.enzu,
                                                                className: css.submenuLink,
                                                                children: "Enzu Bare Metal"
                                                              })
                                                        }),
                                                    JsxRuntime.jsx("li", {
                                                          children: JsxRuntime.jsx(A.make, {
                                                                href: Routes_Services.BareMetal.evocative,
                                                                className: css.submenuLink,
                                                                children: "Evocative Bare Metal"
                                                              })
                                                        }),
                                                    JsxRuntime.jsx("li", {
                                                          children: JsxRuntime.jsx(A.make, {
                                                                href: Routes_Services.BareMetal.evocative,
                                                                className: css.submenuLink,
                                                                children: "Evocative Bare Metal"
                                                              })
                                                        }),
                                                    JsxRuntime.jsx("li", {
                                                          children: JsxRuntime.jsx(A.make, {
                                                                href: Routes_Services.BareMetal.hivelocity,
                                                                className: css.submenuLink,
                                                                children: "Hivelocity Bare Metal"
                                                              })
                                                        }),
                                                    JsxRuntime.jsx("li", {
                                                          children: JsxRuntime.jsx(A.make, {
                                                                href: Routes_Services.BareMetal.zenlayer,
                                                                className: css.submenuLink,
                                                                children: "Zenlayer Bare Metal"
                                                              })
                                                        })
                                                  ],
                                                  className: css.resetList
                                                })
                                          }),
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_Provider.bareMetal,
                                                  className: css.submenuLink,
                                                  children: "Bare Metal Providers"
                                                })
                                          })
                                    ],
                                    className: css.resetList
                                  })
                            }),
                        JsxRuntime.jsx(NavDropdown.make, {
                              title: "Cloud",
                              level: "Primary",
                              device: "Mobile",
                              linksGroup: "MainLinks",
                              children: JsxRuntime.jsxs("ul", {
                                    children: [
                                      JsxRuntime.jsx(NavDropdown.make, {
                                            title: "Cloud RFP",
                                            level: "Secondary",
                                            device: "Mobile",
                                            linksGroup: "MainLinks",
                                            children: JsxRuntime.jsxs("ul", {
                                                  children: [
                                                    JsxRuntime.jsx("li", {
                                                          children: JsxRuntime.jsx(A.make, {
                                                                href: Routes_Project.IaasServer.$$new,
                                                                className: css.submenuLink,
                                                                children: "Servers"
                                                              })
                                                        }),
                                                    JsxRuntime.jsx("li", {
                                                          children: JsxRuntime.jsx(A.make, {
                                                                href: Routes_Project.IaasStorage.$$new,
                                                                className: css.submenuLink,
                                                                children: "Storage"
                                                              })
                                                        })
                                                  ],
                                                  className: css.resetList
                                                })
                                          }),
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_Services.Multicloud.index,
                                                  className: css.submenuLink,
                                                  children: "Deploy Multicloud"
                                                })
                                          }),
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_Provider.cloud,
                                                  className: css.submenuLink,
                                                  children: "Cloud Providers"
                                                })
                                          })
                                    ],
                                    className: css.resetList
                                  })
                            }),
                        JsxRuntime.jsx(NavDropdown.make, {
                              title: "Resource Downloads",
                              level: "Primary",
                              device: "Mobile",
                              linksGroup: "MainLinks",
                              children: JsxRuntime.jsx("ul", {
                                    children: Belt_Array.map(props.resources, (function (resource) {
                                            var slug = resource.slug;
                                            return JsxRuntime.jsx("li", {
                                                        children: slug === "all-resources" ? JsxRuntime.jsx(A.make, {
                                                                href: Routes_Resource.resourcesIndex,
                                                                className: css.submenuLink,
                                                                children: resource.navigationName
                                                              }) : JsxRuntime.jsx(A.make, {
                                                                href: "/resources/" + resource.slug,
                                                                className: css.submenuLink,
                                                                children: resource.navigationName
                                                              })
                                                      }, resource.slug);
                                          })),
                                    className: css.resetList
                                  })
                            }),
                        JsxRuntime.jsx(NavDropdown.make, {
                              title: "Blog",
                              level: "Primary",
                              device: "Mobile",
                              linksGroup: "MainLinks",
                              children: JsxRuntime.jsxs("ul", {
                                    children: [
                                      JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: Routes_BlogPost.news,
                                                  className: css.submenuLink,
                                                  children: "All News"
                                                })
                                          }, "all-news"),
                                      Belt_Array.map(props.blogLinks, (function (news) {
                                              return JsxRuntime.jsx("li", {
                                                          children: JsxRuntime.jsx(A.make, {
                                                                href: Routes_BlogPost.newsCategories(news.slug),
                                                                className: css.submenuLink,
                                                                children: news.navigationName
                                                              })
                                                        }, news.slug);
                                            }))
                                    ],
                                    className: css.resetList
                                  })
                            }),
                        JsxRuntime.jsx("li", {
                              children: JsxRuntime.jsxs(Control.make, {
                                    className: Cx.cx([
                                          css.firstLevelLink,
                                          css.submenuLink,
                                          css.subMenuBtnCart
                                        ]),
                                    onClick: (function (param) {
                                        toggleCart();
                                      }),
                                    children: [
                                      JsxRuntime.jsx(IconCart.make, {
                                            size: "LG",
                                            color: "White",
                                            className: css.icon
                                          }),
                                      "Cart",
                                      JsxRuntime.jsx("span", {
                                            children: String(totalCartQuantity),
                                            className: css.cartCounter
                                          })
                                    ]
                                  })
                            }),
                        tmp$1
                      ],
                      className: css.linksContainer
                    }),
                className: css.navContainer
              }),
          className: css.mainNavigation
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs("header", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("a", {
                              children: JsxRuntime.jsx("img", {
                                    className: css.logo,
                                    alt: "Logo",
                                    src: LogoWhiteSvg
                                  }),
                              "aria-label": "Datacenters.com",
                              href: "/"
                            }),
                        JsxRuntime.jsx("button", {
                              className: css.openMenu,
                              onClick: (function (_event) {
                                  dispatch("Toggle");
                                })
                            })
                      ],
                      className: css.topNavigation,
                      style: {
                        background: "linear-gradient(135deg, rgba(5, 15, 57, " + alpha + ") 0%, rgba(25, 63, 119, " + alpha + ") 100%)"
                      }
                    }),
                tmp,
                JsxRuntime.jsx(Cart.make, {
                      isOpen: props.isCartOpen,
                      closeModal: toggleCart,
                      setCartQty: setCartQty,
                      userLoginStatus: userLoginStatus
                    })
              ],
              className: css.outerWrapper
            });
}

var MobileNavigation = {
  css: css,
  initialState: initialState,
  reducer: reducer,
  make: MobileNavigation$MobileNavigation
};

function MobileNavigation$default(props) {
  return JsxRuntime.jsx(MobileNavigation$MobileNavigation, {
              userData: User.fromJs(props.userData),
              setUserData: props.setUserData,
              resources: Belt_Array.map(props.resources, ResourceCategory.fromJs),
              userLoginStatus: User.LoginStatus.fromBool(props.isUserSignedIn),
              isCartOpen: props.isCartOpen,
              isConcierge: props.isConcierge,
              isNotOnlyProviderMember: props.isNotOnlyProviderMember,
              toggleCart: props.toggleCart,
              setCartQty: props.setCartQty,
              cartQty: props.cartQty,
              totalCartQuantity: props.totalCartQuantity,
              isLandingPage: props.isLandingPage,
              isHomepage: props.isHomepage,
              isTransparent: props.isTransparent,
              blogLinks: Belt_Array.map(props.blogLinks, BlogLink.fromJs)
            });
}

var $$default = MobileNavigation$default;

export {
  MobileNavigation ,
  $$default as default,
}
/* css Not a pure module */
